import ready from "@nf/support/ready";

/**
 * Export ScrollTop Plugin
 * @returns 
 */

export default async function () {
    await ready();


    // let sidenavToggle = document.querySelectorAll('.subnav-item button');
    let sideNavDropdown = document.querySelectorAll('.subnav-item.sidenav-dropdown');    
    sideNavDropdown.forEach((dropdown) =>{        
        let sideNavToggle = dropdown.querySelector('button')        

        sideNavToggle.addEventListener('click', () =>{    
            ;
            dropdown.classList.toggle('open');
        });
    });
}

