import bookmarkCount from './bookmark-count.js';

export default function BookmarkHandler() {
    const eventElement: HTMLElement = document.querySelector("#event-detail");
    
    if (!eventElement) return;

    const EVENT_ID = eventElement.dataset.event;
    const EVENT_STARTDATE = new Date(eventElement.dataset.startdate);
    const startDateTimestamp = Date.parse(EVENT_STARTDATE.toString());

    const eventID = parseInt(EVENT_ID);
    if (!eventID) return;

    const bookmarkButton: HTMLButtonElement = document.querySelector(".bookmark-button");
    const LOCALSTORAGE_BOOKMARKS = localStorage.getItem("bookmarks");
    let bookmarks = [];

    if (LOCALSTORAGE_BOOKMARKS) {
        bookmarks = JSON.parse(LOCALSTORAGE_BOOKMARKS);
    }

    if (bookmarks.find(item => item.id == eventID)) {
        bookmarkButton.classList.add("bookmark-button-bookmarked");
    }
    
    const bookmarkButtonHandler = () => {
        if (bookmarks.find(item => item.id == eventID)) {
            const indexOfID = bookmarks.findIndex(item => item.id == eventID);
            bookmarks.splice(indexOfID, 1);
            bookmarkButton.classList.remove("bookmark-button-bookmarked");
        } else {
            bookmarks.push({id: eventID, timestamp: startDateTimestamp});
            bookmarkButton.classList.add("bookmark-button-bookmarked");
        }

        localStorage.setItem("bookmarks", JSON.stringify(bookmarks));

        bookmarkCount();
    };

    if (bookmarkButton) bookmarkButton.addEventListener("click", bookmarkButtonHandler);
}
