import ready from "@nf/support/ready";
import { set } from "ol/transform";

/**
 * Export ScrollTop Plugin
 * @returns 
 */

export default async function () {
    // await ready();

    // setTimeout( () => { 
    //     // const filterAreas = document.querySelectorAll('.productsfilter__area');
    //     // const filterTopics = document.querySelectorAll('.productsfilter__area > h4');            
    //     // filterAreas.forEach((area) => {            
    //     //     let topic = area.querySelector('h4');
    //     //     console.log(typeof topic)
    //     //     topic.addEventlistener('click', () =>{                    
    //     //         topic.classList.toggle('open')
    //     //     });
    //     // });

    //     let filterToggle = document.querySelector('.filterToggle');
    //     let filter = document.querySelector('.productsfilter__accordion')
    //     let filterClose = document.querySelector('.productsfilter__close');

    //     if(filterToggle){

    //         filterToggle.addEventListener('click', () =>{
    //             filter.classList.add('open');
    //         });
            
    //         if(filterClose) {
    //             filterClose.addEventListener('click', () =>{
    //                 filter.classList.remove('open');
    //             });
    //         }
    
    //         let topicButton = document.querySelectorAll('.productsfilter__accordionbutton');
    
    
    //         topicButton.forEach((topic) => {            
                            
    //                  topic.addEventListener('click', () =>{                                         
    //                      topic.classList.toggle('open');
    //                  });
    //              });
    //     }

    // }, 1000)
}

