
export default function OpenRegistrationDialog() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const modal = document.querySelector<HTMLDivElement>("#eventRegisterModal");
    const button = document.querySelector<HTMLButtonElement>("#event-register-button")

    if(urlParams.get("registration") && urlParams.get("registration") === "1" && modal && button) {
        button.click();
    }
}
