import ready from "@nf/support/ready";

/**
 * Export ScrollTop Plugin
 * @returns 
 */

export default async function () {
    await ready();


    // let sidenavToggle = document.querySelectorAll('.subnav-item button');
    let sideNavDropdown = document.querySelectorAll('.attributes-accordion__trigger');    
    sideNavDropdown.forEach((trigger) =>{                      

        trigger.addEventListener('click', () =>{                
            trigger.classList.toggle('open');
        });
    });
}

