 export default function DownloadCTA() {

    const buttonCTA = document.getElementById("MoreButton");
    const buttonGradient = document.getElementById("CardGradient");
    const Gradient = document.getElementById("LoadGradient");

    if (!buttonCTA) return;

    function remove(event) {
       event.target.style.display = "none";
       buttonGradient.classList.remove("card_load");
       buttonGradient.classList.add("card_open");
       Gradient.classList.remove("gradient_load");
    }

    buttonCTA.addEventListener("click", remove);
 }

