
import '../scss/main/theme.scss';

import { invokeKeenSlider } from "@nf/keen-slider-bridge";

import invokeBootstrap from "./plugins/bootstrap";
import invokeLightbox from "./plugins/lightbox";
import invokeNavbar from "./plugins/navbar";
import invokeScrollTop from "./plugins/scroll-top";
import DownloadCTA from "./plugins/DownloadCTA";
import invokeproductsFilter from "./plugins/productsFilter";
import invokeSideNav from "./plugins/sidenav";
import invokeAccordion from "./plugins/accordion";
import invokeBookmarks from "./plugins/bookmark-handler";
import invokeOpenRegistrationDialog from "./plugins/open-registration-dialog";
import invokeBookmarkCount from "./plugins/bookmark-count";
import invokeSlider from "./plugins/slider";
import invokeHero from "./plugins/hero";

// Invoke NFCore Plugins
invokeBootstrap();
invokeKeenSlider();
invokeLightbox();
invokeNavbar();
invokeScrollTop();
DownloadCTA();
invokeproductsFilter();
invokeSideNav();
invokeAccordion();
invokeBookmarks();
invokeOpenRegistrationDialog();
invokeBookmarkCount();
invokeSlider();
invokeHero();
