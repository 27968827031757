import ready from "@nf/support/ready";

/**
 * Export ScrollTop Plugin
 * @returns 
 */

export default async function () {
    await ready();
    let images = document.querySelectorAll('.hero__img img');
    if (images.length <= 1) {
        return;
    }


    let lastImg = 0;
    function randomImage () {
        let rng = -1;
        let emergencyExit = 7;
        do {
            rng = Math.floor(Math.random() * images.length);   
            if (emergencyExit-- <= 0) {
                break;
            }
        } while (lastImg === rng);

        lastImg = rng;
        images.forEach((img) =>{
            //img.hidden = true;
            img.classList.add('hidden');
        });
        //images[rng].hidden = false; 
        images[rng].classList.remove('hidden');
    }
 randomImage();
}

