import EmblaCarousel from 'embla-carousel'
import ready from "@nf/support/ready";


export default async function () {

    const OPTIONS = {
        slidesToScroll: 'auto'
    }

    const emblaNode = document.querySelector('.productteaserslider')

    

    if (emblaNode) {
        const viewportNode = emblaNode.querySelector('.embla__viewport')

        // Grab button nodes
        const prevBtnNode = document.querySelector('.productteaser__header .embla__prev')
        const nextBtnNode = document.querySelector('.productteaser__header .embla__next')

        const emblaApi = EmblaCarousel(viewportNode, OPTIONS)

        const addTogglePrevNextBtnsActive = (emblaApi, prevBtn, nextBtn) => {
            const togglePrevNextBtnsState = () => {
              if (emblaApi.canScrollPrev()) prevBtn.removeAttribute('disabled')
              else prevBtn.setAttribute('disabled', 'disabled')
          
              if (emblaApi.canScrollNext()) nextBtn.removeAttribute('disabled')
              else nextBtn.setAttribute('disabled', 'disabled')
            }
          
            emblaApi
              .on('select', togglePrevNextBtnsState)
              .on('init', togglePrevNextBtnsState)
              .on('reInit', togglePrevNextBtnsState)
          
            return () => {
              prevBtn.removeAttribute('disabled')
              nextBtn.removeAttribute('disabled')
            }
          }
          
          const addPrevNextBtnsClickHandlers = (emblaApi, prevBtn, nextBtn) => {
            const scrollPrev = () => {
              emblaApi.scrollPrev()
            }
            const scrollNext = () => {
              emblaApi.scrollNext()
            }
            prevBtn.addEventListener('click', scrollPrev, false)
            nextBtn.addEventListener('click', scrollNext, false)
          
            const removeTogglePrevNextBtnsActive = addTogglePrevNextBtnsActive(
              emblaApi,
              prevBtn,
              nextBtn
            )
          
            return () => {
              removeTogglePrevNextBtnsActive()
              prevBtn.removeEventListener('click', scrollPrev, false)
              nextBtn.removeEventListener('click', scrollNext, false)
            }
          }
        
          const removePrevNextBtnsClickHandlers = addPrevNextBtnsClickHandlers(
            emblaApi,
            prevBtnNode,
            nextBtnNode
          )
          // const removeDotBtnsAndClickHandlers = addDotBtnsAndClickHandlers(
          //   emblaApi,
          //   dotsNode
          // )
          
          emblaApi.on('destroy', removePrevNextBtnsClickHandlers)
          // emblaApi.on('destroy', removeDotBtnsAndClickHandlers)
          
        // prevButtonNode.addEventListener('click', embla.scrollPrev, false)
        // nextButtonNode.addEventListener('click', embla.scrollNext, false)
    }
}





  